import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/Layout";
import { LocalisedPageContext } from "../../types/PageTypes";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SectionPage, { SectionPageInterface } from "../../components/SectionPage";
import ProductLinePage, { ProductLinePageInterface } from "../../components/ProductLinePage";
import CampaignPage, { CampaignPageInterface } from "../../components/CampaignPage";
import SubSectionPage, { SubSectionPageInterface } from "../../components/SubSectionPage";
import { LocalizedContextInterface } from "../../types/SanityTypes";
import Popup from "../../components/Popup";

// Expected results of the graphql query made by this component.
type SectionQueryProps = {
  sanitySectionPage: SectionPageInterface["data"];
};

type ProductLinePageQueryProps = {
  sanityProductLinePage: ProductLinePageInterface["data"];
};

type CampaignPageQueryProps = {
  sanityLandingPage: CampaignPageInterface["data"];
};

type SubSectionPageQueryProps = {
  sanitySubSectionPage: SubSectionPageInterface["data"];
};

type LandingPageContentUnionType =
  | SectionQueryProps
  | ProductLinePageQueryProps
  | CampaignPageQueryProps
  | SubSectionPageQueryProps;

type LandingPageProps = LandingPageContentUnionType & LocalizedContextInterface;

type SectionPageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String, $market: String) {
    sanitySectionPage(_id: { eq: $_id }) {
      ...SectionPageFieldsFull
    }
    sanityProductLinePage(_id: { eq: $_id }) {
      ...ProductLinePageFieldsFull
    }
    sanityLandingPage(_id: { eq: $_id }) {
      ...LandingPageFieldsFull
    }
    sanitySubSectionPage(_id: { eq: $_id }) {
      ...SubSectionPageFieldsFull
    }
    ...LocalizedContext
  }
`;

const LandingPage = (props: PageProps<LandingPageProps, SectionPageContext>) => {
  const language = props.pageContext.language;
  const page =
    (props.data as SectionQueryProps).sanitySectionPage ||
    (props.data as ProductLinePageQueryProps).sanityProductLinePage ||
    (props.data as CampaignPageQueryProps).sanityLandingPage ||
    (props.data as SubSectionPageQueryProps).sanitySubSectionPage;
  const { htmlLang } = useSiteMetadata(language);
  const pageTitle =
    page.seo?.metaTitle ||
    page.main?.headline.primaryText +
      " " +
      page.main?.headline.secondaryText +
      " " +
      page.main?.headline.tertiaryText ||
    (page as unknown as CampaignPageQueryProps["sanityLandingPage"]).headline?.primaryText +
      " " +
      (page as unknown as CampaignPageQueryProps["sanityLandingPage"]).headline?.secondaryText +
      " " +
      (page as unknown as CampaignPageQueryProps["sanityLandingPage"]).headline?.tertiaryText ||
    (page as unknown as ProductLinePageQueryProps["sanityProductLinePage"]).heading;
  const pageDescription =
    page.seo?.metaDescription ||
    page.main._rawShortIntro[0].children[0].text ||
    page._rawIntroduction[0].children[0].text;
  const heroImage = page.main
    ? page.main._rawHeroImage
    : (page as unknown as ProductLinePageQueryProps["sanityProductLinePage"])._rawHeroImage;
  const theme = (page as unknown as CampaignPageQueryProps["sanityLandingPage"]).theme;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"keySection"}
      language={language}
      theme={theme}
    >
      <StructuredData
        type={"WebPage"}
        headline={pageTitle}
        description={pageDescription}
        slug={page.slug.current}
        data={heroImage}
        customData={page.seo.jsonld}
        language={htmlLang}
      />
      <SEO
        title={pageTitle}
        description={pageDescription}
        imageUrl={
          page.slug?.current?.toLowerCase() === "metathon"
            ? page.landingSections[0]?._rawHeroBgImage?.asset?.url
            : heroImage?.asset?.url
        }
        imageAlt={heroImage?.asset?.url}
      />
      {(props.data as SectionQueryProps).sanitySectionPage && <SectionPage data={page} />}
      {(props.data as ProductLinePageQueryProps).sanityProductLinePage && (
        <ProductLinePage data={page as unknown as ProductLinePageQueryProps["sanityProductLinePage"]} />
      )}
      {(props.data as CampaignPageQueryProps).sanityLandingPage && (
        <CampaignPage data={page as unknown as CampaignPageQueryProps["sanityLandingPage"]} />
      )}
      {(props.data as SubSectionPageQueryProps).sanitySubSectionPage && (
        <SubSectionPage data={page as unknown as SubSectionPageQueryProps["sanitySubSectionPage"]} />
      )}
      {page?.popup && <Popup data={page?.popup} />}
    </Layout>
  );
};

export default LandingPage;
